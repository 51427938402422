
.headers[data-v-4ddba177]{
  background: #FFFFFF;
  padding: 14px 12px;
}
.headers[data-v-4ddba177] .el-button{
  height: 28px;
}
[data-v-4ddba177].el-button+.el-button {
  margin-left: 0px;
}
